import React from 'react'
import Layout from '../components/layout'

export default function notFoundPage() {
  return (
    <Layout>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="columns twelve">
              <h1 className="center-text">404</h1>
              <p>Page Not Found</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
